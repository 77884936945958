<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-image">
        <img
          v-if="spotCategory.icon"
          :src="spotCategory.icon"
          class="argon-image mb-2"
        />
        <img
          v-else
          src="/img/kw-default-image.svg"
          class="argon-image default"
        />
      </div>
    </div>
    <div class="all-infos-text">
      <dl class="row" v-if="spotCategory.name">
        <dt>{{ $t("SPOT_CATEGORIES.SPOT_CATEGORY_NAME") }}</dt>
        <dd>
          {{ spotCategory.name }}
        </dd>
      </dl>

      <dl class="row" v-if="spotCategory.form_type">
        <dt>{{ $t("SPOT_CATEGORIES.SPOT_FORM_TYPE") }}</dt>
        <dd>
          {{ $t(`SPOT_CATEGORIES.SPOT_FORM_TYPE_${spotCategory.form_type}`) }}
        </dd>
      </dl>

      <dl class="row" v-if="spotCategory.booking_form_type">
        <dt>{{ $t("SPOT_CATEGORIES.BOOKING_FORM_TYPE") }}</dt>
        <dd>
          {{
            $t(
              `SPOT_CATEGORIES.BOOKING_FORM_TYPE_${spotCategory.booking_form_type}`
            )
          }}
        </dd>
      </dl>

      <dl class="row">
        <dt>{{ $t("CONFIGURATION.SHOW_AS_FILTER") }}</dt>
        <dd>
          {{ spotCategory.show_as_filter ? $t('COMMON.YES') : $t('COMMON.NO') }}
        </dd>
      </dl>

      <dl class="row full" v-if="spotCategory.excerpt">
        <dt>
          {{ $t("COMMON.COMMENT") }}
        </dt>
        <dd>
          <div v-html="spotCategory.excerpt"></div>
        </dd>
      </dl>

      <dl class="row">
        <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd>
          {{ spotCategory.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd>
          {{ spotCategory.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "spot-category-view-global",

  props: ["spotCategory"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    contactUpdated() {
      this.$emit("spotCategoryUpdated", true);
    },
  },

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
