<template>
  <div class="container-fluid">
    <spot-category-form
      :loading="loading"
      :spotCategoryData="spotCategory"
      :formErrors="formErrors"
      :hideOrganization="!!globalOrganization"
      @spotCategorySubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseSpotCategoryModal="onCloseSpotCategoryModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultSpotCategory from "../defaultSpotCategory";
import SpotCategoryForm from "../partials/SpotCategoryForm.vue";
import { mapGetters } from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    SpotCategoryForm,
  },

  mixins: [alertLeave],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },

  data() {
    return {
      spotCategory: cloneDeep(defaultSpotCategory),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.spotCategory.organization = this.globalOrganization;
  },

  methods: {
    async handleSubmit(spotCategory) {
      this.loading = true;

      const spotCategoryData = cloneDeep(spotCategory);
      delete spotCategoryData.id;

      try {
        await this.$store.dispatch("spotCategories/add", spotCategoryData);
        this.$notify({
          type: "success",
          message: this.$t("SPOT_CATEGORIES.SPOT_CATEGORY_ADDED"),
        });
        const spotCategory = await this.$store.getters[
          "spotCategories/spotCategory"
        ];
        this.$emit("onViewSpotCategory", spotCategory, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseSpotCategoryModal() {
      this.$emit("onCloseSpotCategoryModal");
    },
  },
};
</script>
